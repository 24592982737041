import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ScoreDisplay from '../Common/ScoreDisplay';
import NumberPad from '../Common/NumberPad';
import { MultiplierType, AufnahmeData, ThrowData } from '../../types/DartsTypes'
import gameService from '../../services/GameService';
import Button from '../Common/Button';

interface Darts501FormProps {
  playerNames: string[];
  initialPoints: number;
  onEndGame: () => void;
}

const Darts501Form: React.FC<Darts501FormProps> = ({ playerNames, initialPoints, onEndGame }) => {

  const [finished, setFinished] = useState<boolean>(false);

  const [playerAufnahmen, setPlayerAufnahmen] = useState<AufnahmeData[][]>(playerNames.map(() => []))
  const [playerScores, setPlayerScores] = useState(playerNames.map(() => initialPoints));
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState(0);

  const [currentAufnahme, setCurrentAufnahme] = useState<AufnahmeData>({ throw_list: [], busted: false });
  const [scoreBeforeAufnahme, setScoreBeforeAufnahme] = useState<number>(initialPoints);

  const [gameStartTimestamp, setGameStartTimestamp] = useState<Date>(new Date())
  const [playerToThrowFirstIdx, setPlayerToThrowFirstIdx] = useState<number>(0)


  const prepareAndSendGameResults = async (isGameEnd: boolean) => {
    // Prepare game data
    let gameData = {
      game_id: uuidv4(),
      start_timestamp: gameStartTimestamp.toISOString().slice(0, -1),
      end_timestamp: new Date().toISOString().slice(0, -1),
      players: playerNames,
      finished,
      aufnahmen: playerAufnahmen,
      initial_points: initialPoints,
      is_double_out: true,
      first_thrower_idx: playerToThrowFirstIdx
    };

    // Send game results
    try {
      const responseData = await gameService.sendGameResults(gameData);
      console.log('Success:', responseData);
      // Handle further logic
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
    // Reset game state if it's the end of the game
    if (isGameEnd) {
      resetGameState();
    }
  };

  const resetGameState = () => {

    setPlayerScores(playerNames.map(() => initialPoints));
    setCurrentAufnahme({ throw_list: [], busted: false });
    setPlayerAufnahmen(playerNames.map(() => []))
    setScoreBeforeAufnahme(initialPoints);

    const newPlayerToThrowFirstIdx = (playerToThrowFirstIdx + 1) % playerNames.length
    setCurrentPlayerIndex(newPlayerToThrowFirstIdx)
    setPlayerToThrowFirstIdx(newPlayerToThrowFirstIdx)
    setFinished(false);
    onEndGame()
  };

  const handleGameEnd = (event: React.FormEvent) => {
    event.preventDefault();
    prepareAndSendGameResults(true);
  }


  const handleThrow = (value: number, isSpecial: boolean = false, multiplier: MultiplierType): void => {

    if (finished) {
      return
    }
    let calculatedScore: number = value;
    let calculatedMultiplier: MultiplierType = multiplier
    let describer: string = ""

    if (!isSpecial) {
      calculatedScore = multiplier === 'double' ? value * 2 :
        multiplier === 'treble' ? value * 3 : value;

      describer = multiplier === 'double' ? 'D' + value :
        multiplier === 'treble' ? 'T' + value : 'S' + value;
    } else {
      describer = value === 25 ? 'SB' : value === 50 ? 'BE' : "MI"
      calculatedMultiplier = value === 50 ? 'double' : 'single'
    }

    let newThrow: ThrowData = { score: calculatedScore, multiplier: calculatedMultiplier, describer, fieldHit: value };
    const updatedThrows: ThrowData[] = [...currentAufnahme.throw_list, newThrow]
    let newAufnahme: AufnahmeData = { busted: false, throw_list: updatedThrows }

    const updatedScores = [...playerScores]

    switch (checkOverthrown(newThrow)) {
      case 'continue':
        updatedScores[currentPlayerIndex] -= calculatedScore

        setCurrentAufnahme(newAufnahme)
        setPlayerScores(updatedScores)

        if (newAufnahme.throw_list.length === 3) {
          finishAufnahme(newAufnahme)
        }
        break;
      case 'overthrown':
        newAufnahme = { busted: true, throw_list: [...currentAufnahme.throw_list, newThrow] }
        setCurrentAufnahme(newAufnahme)
        finishAufnahme(newAufnahme, true)
        break;
      case 'finished':
        updatedScores[currentPlayerIndex] -= calculatedScore

        setPlayerScores(updatedScores)
        setCurrentAufnahme(newAufnahme)
        finishAufnahme(newAufnahme)
        setFinished(true);
    }

  };

  const checkOverthrown = (thrw: ThrowData): 'continue' | 'overthrown' | 'finished' => {

    if (playerScores[currentPlayerIndex] - thrw.score > 1) {
      return 'continue'
    } else if (playerScores[currentPlayerIndex] - thrw.score === 0 && thrw.multiplier === "double") {
      return 'finished'
    }

    return 'overthrown'
  }

  const finishAufnahme = (aufnahme: AufnahmeData, overthrown: boolean = false): void => {

    // Invalid finish, reset score to previous state
    const updatedPlayerAufnahmen = [...playerAufnahmen]
    const thisPlayerAufnahmen = updatedPlayerAufnahmen[currentPlayerIndex]
    const updatedScores = [...playerScores]
    const nextPlayerIdx = (currentPlayerIndex + 1) % playerNames.length;


    if (overthrown) {
      updatedScores[currentPlayerIndex] = scoreBeforeAufnahme
    }
    setCurrentPlayerIndex(nextPlayerIdx)
    setScoreBeforeAufnahme(playerScores[nextPlayerIdx]);
    // Reset Aufnahme
    updatedPlayerAufnahmen[currentPlayerIndex] = [...thisPlayerAufnahmen, aufnahme]
    setPlayerAufnahmen(updatedPlayerAufnahmen)
    setCurrentAufnahme({ throw_list: [], busted: false });
  };


  const resetGame = (event: React.FormEvent): void => {
    prepareAndSendGameResults(false);
    setPlayerScores(playerNames.map(() => initialPoints));
    setCurrentAufnahme({ throw_list: [], busted: false });
    const newPlayerToThrowFirstIdx = (playerToThrowFirstIdx + 1) % playerNames.length
    setCurrentPlayerIndex(newPlayerToThrowFirstIdx)
    setPlayerToThrowFirstIdx(newPlayerToThrowFirstIdx)
    setPlayerAufnahmen(playerNames.map(() => []))
    setScoreBeforeAufnahme(initialPoints);
    setGameStartTimestamp(new Date())
    setFinished(false);
  };

  return (
    <div className="">
      <h2>Darts {initialPoints} Game</h2>
      <div className="">
        {playerNames.map((name, index) => (
          <ScoreDisplay playerName={name} remainingScore={playerScores[index]} isActivePlayer={index === currentPlayerIndex} aufnahmen={playerAufnahmen[index]} />
        ))}
      </div>
      <NumberPad onThrow={handleThrow} />
      <Button onClick={resetGame}>{finished ? "New Leg" : "Restart Leg"}</Button>
      <Button className="" onClick={handleGameEnd}>End Session</Button>
    </div>
  );
}

export default Darts501Form;