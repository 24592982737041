import React from "react";


const AppHeader: React.FC = () => {

    return (
        <div className="w-full text-center m-xl">
            <h1 className="pb-10 pt-5 text-6xl text-bold">Data Driven Darts</h1>
        </div>
    );
}

export default AppHeader;
