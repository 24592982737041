//Logout button 
import React from 'react';
import { useAuth } from '../../AppContext';
import Button from './Button';


const Logout: React.FC = () => {
    const auth = useAuth();

    return (
        <Button onClick={auth.logOut}>Logout</Button>
    );
};

export default Logout;

