import React, { useState } from 'react';
import { useAuth } from '../../AppContext';
import Button from './Button';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const auth = useAuth();

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('username', username);
        formData.append('password', password);
        try {
            auth.loginAction(formData)
        } catch (error) {
            console.error('ERROR', error);
        }
    }

    return (
        <div className="w-1/2 mx-auto p-4">
            <h2 className="text-2xl">Login</h2>
            <form onSubmit={handleLogin} className="space-y-4">
                <div className="space-y-2">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        className="p-2 border-b border-gray-300"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div className="space-y-2">
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        className="p-2 border-b border-gray-300"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <Button buttonType="submit">Login</Button>
            </form>
        </div>
    )
}
