import React, { useState } from 'react';
import { MultiplierType } from '../../types/DartsTypes';

interface NumberPadProps {
    onThrow: (value: number, isSpecial: boolean, multiplier: MultiplierType) => void;
}

const NumberPad: React.FC<NumberPadProps> = ({ onThrow }) => {
    const [multiplier, setMultiplier] = useState<MultiplierType>('single');

    const handleButtonClick = (value: number, isSpecial: boolean) => {
        onThrow(value, isSpecial, multiplier);
        setMultiplier("single");
    }

    return (
        <div className="flex flex-col items-center justify-center gap-0 p-4 h-full">
            <div className="flex flex-grow items-stretch space-x-2 mb-4">
                {['single', 'double', 'treble'].map((type) => (
                    <button
                        key={type}
                        className={`flex-grow px-4 py-2 border rounded ${multiplier === type ? 'bg-blue-500 text-white' : 'bg-white text-black'} focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
                        onClick={() => setMultiplier(type as MultiplierType)}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </button>
                ))}
            </div>
            <div className="grid grid-cols-4 gap-3">
                {[...Array(20).keys()].map(n => (
                    <button className="px-4 py-2 bg-gray-100 text-black border rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" key={n} onClick={() => handleButtonClick(n + 1, false)}>{n + 1}</button>
                ))}
                <button className="px-4 py-2 bg-gray-100 text-black border rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" onClick={() => handleButtonClick(25, true)}>Single Bull</button>
                <button className="px-4 py-2 bg-gray-100 text-black border rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" onClick={() => handleButtonClick(50, true)}>Bull</button>
                <button className="px-4 py-2 bg-gray-100 text-black border rounded hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" onClick={() => handleButtonClick(0, true)}>Miss</button>
            </div>
        </div>
    );
};

export default NumberPad;
