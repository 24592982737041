import { createContext, useContext, useEffect, useState } from "react";
import { login, checkLoggedIn } from "./services/UserService"; //login from "./services/UserService";

export interface IAuthContext {
    token: string;
    user: string | null;
    loginAction: (formData: FormData) => void;
    logOut: () => void;
    loginError: string | null;
}

type UserCredentials = {
    username: string;
    token: string;
    refresh_token: string;
};


const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<string | null>(null);
    const [token, setToken] = useState(localStorage.getItem("site") || "");
    const [refresh_token, setRefreshToken] = useState(localStorage.getItem("refresh_token") || "");
    const [loginError, setLoginError] = useState<string | null>(null);

    useEffect(() => {
        getUser();
    }, [token, refresh_token]);

    const getUser = async () => {
        if (!user && refresh_token) {
            // Use token to get user from the server
            const response = await checkLoggedIn(refresh_token);
            setUser(response.username);
            setToken(response.access_token);
            setRefreshToken(response.refresh_token);
            localStorage.setItem("site", response.access_token);
            localStorage.setItem("refresh_token", response.refresh_token);
        }
        return user;
    };


    const loginAction = async (formData: FormData) => {
        try {
            const response = await login(formData);
            setUser(response.username);
            setToken(response.access_token);
            setRefreshToken(response.refresh_token);
            localStorage.setItem("site", response.access_token);
            localStorage.setItem("refresh_token", response.refresh_token);
        } catch (error) {
            console.error('ERROR', error);
            setLoginError("Invalid credentials");
        }
    };

    const logOut = () => {
        setUser(null);
        setToken("");
        setRefreshToken("");
        localStorage.removeItem("site");
        localStorage.removeItem("refresh_token");
    };

    return <AuthContext.Provider value={{ token, user, loginError, loginAction, logOut }}>{children}</AuthContext.Provider>;

};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
