import React, { useState } from 'react';
import LoginForm from './components/Common/Login';
import Darts501Form from './components/X01Game/DartsX01Form';
import GameSetupForm from './components/Common/GameSetupForm';
import './App.css';
import AppHeader from './components/Common/AppHeader';
import AuthProvider, { useAuth } from './AppContext';
import Logout from './components/Common/Logout';


const App: React.FC = () => {
  return (
    <div className='w-full'>
      <AuthProvider>
        <InnerApp />
      </AuthProvider>
    </div>
  );
};


const InnerApp: React.FC = () => {

  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [playerNames, setPlayerNames] = useState<string[]>(['']);
  const [initialPoints, setInitialPoints] = useState<number>(501);

  const auth = useAuth();

  const startGame = (names: string[], points: number) => {
    setPlayerNames(names);
    setInitialPoints(points);
    setGameStarted(true);
  };

  const endGame = () => {
    setGameStarted(false)
  }
  return (
    <div>
      <AppHeader />
      <div>
        {!auth.user ?
          <LoginForm />
          : (!gameStarted ?
            <GameSetupForm onStartGame={startGame} initialPlayerNames={playerNames} />
            : (
              <Darts501Form playerNames={playerNames} initialPoints={initialPoints} onEndGame={endGame} />
            ))
        }
        <Logout />
      </div>
    </div>
  )
}



export default App;


