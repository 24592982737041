import axios from 'axios';
import { AufnahmeData } from '../types/DartsTypes';

const baseURL = process.env.BACKEND_URL;


export interface finalGameData {
    finished: boolean
    aufnahmen: AufnahmeData[][]
    start_timestamp: string
    end_timestamp: string
    players: string[]
    game_id: string
}

const sendGameResults = async (gameData: finalGameData) => {
    try {
        const response = await axios.post(`${baseURL}/game`, gameData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default {
    sendGameResults,
};
