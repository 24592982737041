import React, { useState } from 'react';


type initialPointsType = 121 | 301 | 501 | 701 | 1001
interface GameSetupFormProps {
    onStartGame: (playerNames: string[], initialPoints: initialPointsType) => void;
    initialPlayerNames: string[]
}


const GameSetupForm: React.FC<GameSetupFormProps> = ({ initialPlayerNames, onStartGame }) => {
    const [playerNames, setPlayerNames] = useState<string[]>(initialPlayerNames);
    const [initialPoints, setInitialPoints] = useState<initialPointsType>(121);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        onStartGame(playerNames, initialPoints);
    };

    const handleAddRow = (event: React.FormEvent, index: number) => {
        event.preventDefault();
        setPlayerNames([...playerNames.slice(0, index + 1), '', ...playerNames.slice(index + 1)])
    }

    return (
        <div className="text-l py-10 flex justify-center">
            <form onSubmit={handleSubmit} className="space-y-4">
                {playerNames.map((pname, index) => {
                    return <div className='flex items-center space-x-2'>
                        <input
                            type="text"
                            className='block w-6/12 p-2 border border-gray-300 rounded-lg text-bold'
                            value={pname}
                            onChange={(e) => setPlayerNames([...playerNames.slice(0, index), e.target.value, ...playerNames.slice(index + 1)])}
                            placeholder="Player Name"
                        />
                        <button type="button" className='btn btn-primary block w-3/12 bg-persian-blue-800 rounded-lg h-10 text-white' onClick={(e) => { handleAddRow(e, index) }}>+</button>
                        <button
                            type="button"
                            className='btn btn-error block w-3/12 bg-persian-blue-500 border border-persian-blue-900 rounded-lg h-10 text-white'
                            disabled={index === 0}
                            onClick={(e) => {
                                e.preventDefault()
                                setPlayerNames([...playerNames.slice(0, index), ...playerNames.slice(index + 1)])
                            }
                            }>-
                        </button>
                    </div>
                })}
                <div className="flex items-center space-x-3">
                    <label className='w-6/12'>Initial Points:</label>
                    {"   "}
                    <select
                        value={initialPoints}
                        onChange={(e) => setInitialPoints(Number(e.target.value) as initialPointsType)}
                        className='w-6/12'
                    >
                        <option value={121}>121</option>
                        <option value={301}>301</option>
                        <option value={501}>501</option>
                    </select>
                </div>
                <div className="flex items-center space-x-3">
                    <label htmlFor="do">Double out:</label>
                    {"   "}
                    <input type="checkbox" id="do" name="vehicle1" value={1} defaultChecked={true} disabled={true} />
                </div>
                <button disabled={playerNames.some(pn => pn === '')} className="btn btn-wide btn-accent px-5 py-3 rounded-lg text-warmWhite bg-persian-blue-800" type="submit">Start Game</button>
            </form>
        </div>
    );
};

export default GameSetupForm;
