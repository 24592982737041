import React from "react";

type MultiplierType = 'single' | 'double' | 'treble';

interface ThrowData {
    score: number;
    multiplier: MultiplierType;
    describer: string;
}

interface AufnahmeData {
    throw_list: ThrowData[];
    busted: boolean;
}

interface ScoreDisplayProps {
    playerName: string;
    isActivePlayer: boolean;
    remainingScore: number;
    aufnahmen: AufnahmeData[];
}

const ThrowItem: React.FC<ThrowData> = ({ describer }) => (
    <div className="bg-persian-blue-900 text-crispWhite p-1 w-4/5  h-[30px] mx-auto text-center shadow-md">
        {describer}
    </div>
);

const AufnahmeRow: React.FC<AufnahmeData> = ({ throw_list }) => (
    <div className="grid grid-cols-3 gap-1/2">
        {throw_list.map((t, index) => <ThrowItem key={index} {...t} />)}
    </div>
);

const ScoreDisplay: React.FC<ScoreDisplayProps> = ({ playerName, isActivePlayer, remainingScore, aufnahmen }) => {
    const aufnahmenReversed = [...aufnahmen].reverse();

    return (
        <div className="bg-slateGray p-2.5 rounded-lg shadow-md min-w-[200px] max-w-1 text-center">
            <div className={`text-xl mb-1 ${isActivePlayer ? 'font-bold text-successGreen' : ''}`}>{playerName}</div>
            <div className="text-5xl font-bold">{remainingScore}</div>
            <div className="text-xs h-8 grid gap-2.5 min-h-[35px] overflow-y-scroll">
                {aufnahmenReversed.map((aufn, idx) => aufn.throw_list.length > 0 ? <AufnahmeRow key={idx} {...aufn} />  : "")}
            </div>
        </div>
    );
}

export default ScoreDisplay;
