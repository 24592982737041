import axios from 'axios';

const baseURL = "http://localhost:8000"

interface TokenData {
    access_token: string;
    username: string;
    refresh_token: string;
}


export const login = async (formData: FormData): Promise<TokenData> => {
    try {
        const response = await axios.post("/api/token", formData);
        if (response.data.access_token) {
        }
        return { access_token: response.data.access_token, username: response.data.username, refresh_token: response.data.refresh_token };
    } catch (error) {
        const { response } = error as { response: any };
        if (response && response.status === 401) {
            console.log(response.data)
            throw new Error('Invalid credentials');
        } else {
            throw error;
        }
    }
};


export const checkLoggedIn = async (refresh_token: string): Promise<TokenData> => {
    const headers = {
        'Authorization': `Bearer ${refresh_token}`
    }
    try {
        const response = await axios.post("/api/token/refresh", {}, { headers });
        if (response.data.access_token) {
        }
        return { access_token: response.data.access_token, username: response.data.username, refresh_token: response.data.refresh_token };
    } catch (error) {
        const { response } = error as { response: any };
        if (response && response.status === 401) {
            console.log(response.data)
            throw new Error('Invalid credentials');
        } else {
            throw error;
        }
    }
}
