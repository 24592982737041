import React from 'react';

type ButtonProps = {
    children: React.ReactNode;
    onClick?: (...args: any[]) => void;
    className?: string;
    buttonType?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<ButtonProps> = ({ children, onClick, className = '', buttonType = 'button' }) => {
    return (
        <button
            type={buttonType}
            onClick={onClick}
            className={`${className} px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-opacity-50`}
        >
            {children}
        </button>
    );
};

export default Button;
